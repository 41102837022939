.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}
.payment-tab {
    @media screen and (max-width: 700px) {
      .payment-tab-header {
        font-size: 9px;
      }
    }
    .payment-tab-header::-webkit-scrollbar {
      display: none;
    }
    .payment-tab-header {
      height: 70px;
      margin-bottom: 1em;
      display: flex;
      width: 100%;
    .payment-tab-detail-style{
      position: relative;
      display: flex;
      scrollbar-color: transparent transparent;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: hidden;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .payment-tab-detail-style ::-webkit-scrollbar{
      display: none;
    }

      ul {
        padding: 0;
        margin: 0;
        // overflow: auto;
        // /* this will hide the scrollbar in mozilla based browsers */
        // overflow: -moz-scrollbars-none;
        // scrollbar-width: none;
        // /* this will hide the scrollbar in internet explorers */
        // -ms-overflow-style: none;
        li {
          list-style: none;
          float: left;
          margin-right: 35px;
          position: relative;
          color: #c8c8c8;
          cursor: pointer;
          &.active {
            color: $dark-grey;
            &:after {
              position: absolute;
              content: '';
              width: 100%;
              height: 4px;
              background-color: $dark-blue;
              left: 0;
              bottom: -16px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .payment-tab-detail {
      padding: 1em;
      position: relative;
      .payment-tab-detail-list {
        display: none;
       
        &.active {
          display: block;
          ul {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            // overflow: auto;
            // /* this will hide the scrollbar in mozilla based browsers */
            // overflow: -moz-scrollbars-none;
            // scrollbar-width: none;
            // /* this will hide the scrollbar in internet explorers */
            // -ms-overflow-style: none;
            li {
              list-style: none;
              float: left;
              margin-right: 35px;
              position: relative;
              color: #c8c8c8;
              cursor: pointer;
              &.active {
                color: $dark-grey;
                &:after {
                  position: absolute;
                  content: '';
                  width: 100%;
                  height: 4px;
                  background-color: $dark-blue;
                  left: 0;
                  bottom: -4px;
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .cc_icon {
          margin: 0 5px;
          max-height: 17px;
        }
        .ic_verified {
          width: 180px;
        }
        .ic_slide_arrow {
          position: absolute;
          top: 47px;
          cursor: pointer;
          &.prev {
            left: 0;
          }
          &.next {
            right: 0;
            transform: rotate(180deg);
          }
        }
        .img-wrapper-transfer {
          position: relative;
          width: 100%;
          height: 240px;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .tf-bersama-caption {
          max-width: 295px;
          margin: 0 auto;
          line-height: 22px;
          margin-bottom: 123px;
        }
        .slider-payment-wrapper {
          position: relative;
          padding: 0 20px;
          // margin-bottom: 4em;
          .slider-payment-list2 {
            position: relative;
            width: 130px;
            height: 75px;
            background-color: #eeeeee;
            border-radius: 4px;
            margin: 0 4px;
            // opacity: 1;
          }
          .slider-payment-list,
          .slick-slide {
            position: relative;
            width: 130px;
            height: 75px;
            background-color: #eeeeee;
            border-radius: 4px;
            margin: 0 4px;
            opacity: 0.3;
            cursor: pointer;
            &.slick-current {
              opacity: 1;
            }
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .slick-slide.slick-cloned {
            margin-left: -5px;
            visibility: hidden;
          }
        }
        .slider-payment-detail {
          .slider-payment-detail-list {
            display: none;
            &.active {
              display: block;
            }
            .number-wrapper {
              width: 19px;
              height: 19px;
              color: #fff;
              border-radius: 50%;
              background-color: #38c6d7;
              position: relative;
              margin-top: 2px;
              div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 12px;
              }
            }
            .how-to-payment {
              max-width: 420px;
            }
          }
        }
      }
    }
  }