.fz24 {
  font-size: 24px;
}

.fz18 {
  font-size: 18px;
}

.fz14 {
  font-size: 14px;
}

.fz12 {
  font-size: 12px;
}

.fz11 {
  font-size: 11px;
}

.fz10 {
  font-size: 10px;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w100-mobile {
  @include mobile {
    width: 100% !important;
  }
}

.w40 {
  width: 40%;
}

.w30 {
  width: 30%;
}

.w20 {
  width: 20%;
}
