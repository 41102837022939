.btnPayment {
  // min-width: 100px;
  display: inline-block;
  padding: 8px 30px;
  margin-bottom: 0;
  font-family: Mulish;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: none;
  border-radius: 5px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.48);
  outline: none !important;
  margin-right: 5px;
  transition: 0.2s all;

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-family: sans-serif;
  }

  &:last-child {
    margin-right: 0;
  }
  &.btnPayment-blue {
    background:#065BAA;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    font-size: 13px;
    padding: 6px 20px 8px;
    &:hover {
      background: darken(#0066b3, 5%);
    }
  }
  &.btnPayment-pay {
    height: 56px;
    background:#065BAA;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    font-size: 18px;
    padding: 6px 20px 8px;
    box-shadow: 0 4px 0 rgba(18, 54, 85, 1);
    &:hover {
      background: darken(#0066b3, 5%);
    }
  }
}
