@font-face {
  font-family: Mulish;
  // src: url(Mulish.ttf);
  // src: local("Mulish"), url(./Mulish.ttf) format("ttf");
}

@font-face {
  font-family: Mulish;
  // src: local("Mulish"), url(./Mulish.ttf) format("ttf");
  //   src: url(Mulish.ttf);
}

$white-dop: #f0f0f0;
$light-grey: #a6a6a6;
$light-grey2: #9b9b9b;
$dark-grey: #646464;
$cyan:#065BAA;
$dark-blue:#065BAA;

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

body {
  background: #fff;
  color: #4b4c4c;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  font-family: Mulish;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.main-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: $white-dop;
  .custom-container {
    position: relative;
    width: 90vw;
    margin: 0 auto;
    overflow-x: hidden;
    margin: 0 auto;
  }
  .notif-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    min-width: 650px;
    padding: 4em;
    p {
      font-size: 14px;
      max-width: 400px;
      margin: 0 auto 2em;
    }
    .ic_notif_image {
      width: 395px;
      margin: 0 auto 3em;
    }
    button {
      width: 200px;
    }
  }
}
.point3-section{
  margin: 1rem;
  p{
    font-size: 12px;
    word-break: break-word;
    letter-spacing: normal;
    font-weight: 500;
  }
}
button[disabled]{
background: #808080d4 !important;
color: #d7d1d1 !important;
}
.close-modal{
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    cursor: pointer;
}
.text-center {
  text-align: center;
}

.bold {
  font-family: Mulish;
}

.cyan {
  color: $cyan;
}

.light-grey {
  color: $light-grey;
}

.light-grey2 {
  color: $light-grey2;
}

.dark-grey {
  color: $dark-grey;
}

.dark-blue {
  color: $dark-blue;
}

.bg-white {
  background-color: #fff;
}

.bg-white-dop {
  background-color: $white-dop;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.nowrap {
  white-space: nowrap;
}

// header
header {
  padding: 20px 0 26px;
  .main-logo {
    width: 235px;
  }
  .step-status-wrapper {
    margin-top: 10px;
    .step-separator {
      width: 150px;
      height: 1px;
      background-color: #939393;
      margin: 0 -8px;
      margin-top: 14px;
      &.last-step {
        background-color:#065BAA;
        // margin-left: -5px;
        margin-left: -1px;
        margin-right: -17px;
      }
    }
  }
}

.header-separator-gradient {
  position: relative;
  width: 100%;
  height: 5px;
  background: rgba(56, 199, 215, 1);
  background: -moz-linear-gradient(90deg,#50b848 0,#50b848 27%,#065BAA 65%,#065BAA);
  background: -webkit-gradient(90deg,#50b848 0,#50b848 27%,#065BAA 65%,#065BAA);
  background: -webkit-linear-gradient(90deg,#50b848 0,#50b848 27%,#065BAA 65%,#065BAA);
  background: -o-linear-gradient(90deg,#50b848 0,#50b848 27%,#065BAA 65%,#065BAA);
  background: -ms-linear-gradient(90deg,#50b848 0,#50b848 27%,#065BAA 65%,#065BAA);
  background: linear-gradient(90deg,#50b848 0,#50b848 27%,#065BAA 65%,#065BAA);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#38c7d7', endColorstr='#0f5088', GradientType=1 );
}

footer {
  height: 40px;
  color: #fff;
  padding: 15px 0;
  background: #065BAA;
  ul {
    padding: 0;
    margin: 0;
    li {
      float: left;
      list-style: none;
      margin-right: 20px;
      font-size: 11px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.content {
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding: 25px 0 50px;
  overflow: auto;
  min-height: calc(100vh - 152px);
  .expired-time {
    margin-top: 7px;
  }
  .content-detail {
    position: relative;
    // padding-left: 4em;
    @media screen and (max-width: 1200px) {
      padding-left: 0;
    }
    .payment-method {
      width: calc(100% - 350px);
     
      .switch {
        position: relative;
        display: inline-block;
        width: 52px;
        height: 26px;
      }
      .switch input {
        display: none;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #c8c8c8;
        border: 1px solid #e4e4e4;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .slider:before {
        position: absolute;
        content: '';
        height: 21px;
        width: 21px;
        left: 3px;
        bottom: 2px;
        background-color: #fff;
        box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.1);
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #6edc5f;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(23px);
        -ms-transform: translateX(23px);
        transform: translateX(23px);
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 26px;
      }

      .slider.round:before {
        border-radius: 50%;
      }

      .voucher-code-wrapper {
        .js-input-checkbox {
          margin-top: 5px;
        }
        .voucher-code-caption {
          color: #c8c8c8;
          margin-top: 8px;
          &.active {
            color: #646464;
          }
        }
        .form-group {
          margin-top: -5px;
          // display: none;
        }
        .form-control {
          margin-bottom: 0;
        }
      }
    }
    .booking-detail {
      width: 350px;
      padding-left: 25px;
     
    }
    .bordered-box {
      border: 1px solid #d5d5d5;
      background-color: #fff;
      width: 100%;
    }

    .bbgrey {
      border-bottom: 1px solid #d5d5d5;
    }
  }
}
.attn{
  padding: 1.5rem;
  .input-attention{
    font-size: 12px;
    cursor: pointer;
    height: 11px;
    // padding: 1.5rem;
  }
  a{
    text-decoration: none;
    padding: 0px 2px;

  }
  label{
    color: #4b4c4c;
    span{
      cursor:pointer;
       color:#065BAA;
       &:hover {
       color: #065baa8c;
  }
  
    }
  }
}
// PAGE LOADER SPINNER
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
}

.spinner-2 {
  margin: 0 auto;
  width: 70px;
  text-align: center;
  display: flex;
  align-items: center;
}

.spinner > div,
.spinner-2 > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1,
.spinner-2 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2,
.spinner-2 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
